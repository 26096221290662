import Game from "./Cube";
import { useEffect, useRef, useState } from "react";
import { animated, useSpring } from "react-spring";

export default function Cube() {
  const container = useRef(null);
  const [showMsg, setShowMsg] = useState(false);
  const styles = useSpring({ opacity: showMsg ? 1 : 0 });
  useEffect(() => {
    const game = new Game(container.current, () => {
      setShowMsg(false);
    });
    setTimeout(() => {
      // @ts-ignore
      game.transition.float();
      // @ts-ignore
      game.transition.cube(true);
      setTimeout(() => setShowMsg(true), 2000);
    }, 500);
  }, []);
  return (
    <div className={"flex flex-col items-center relative w-full h-72 lg:h-96"}>
      <animated.div
        style={styles}
        className={`absolute transform translate-y-10 uppercase text-gray-300`}
      >
        DOUBLE TAP
      </animated.div>
      <div className={"relative w-full h-full"} ref={container} />
    </div>
  );
}
