import Cube from './Cube'
import tw from 'twin.macro'
import styled from 'styled-components'

const Logo = styled.div`
  font-size: clamp(2.5rem, 25vw, 8rem);
`

const Link = tw.a`
  transition-opacity
  duration-300
  opacity-60
  hover:opacity-100
`

function App() {
  return (
    <div
      className={
        'flex flex-col items-center text-gray-500 h-screen select-none'
      }
    >
      <Cube />
      <section className={'flex flex-col items-center'}>
        <Logo className={'text-gray-800 text-9xl font-normal'}>QL</Logo>
        <div className={'text-lg font-light tracking-wider'}>QRYPTO LABS</div>
      </section>
      <section
        className={'flex flex-col items-center py-24 lg:py-32 space-y-1.5'}
      >
        <div className={'text-gray-800 font-semibold'}>Coding the Future</div>
        <div className={'text-sm uppercase'}>
          Smart Contracts | NFTs | dApps | Design
        </div>
        <div>
          <Link
            className={'text-sm font-bold'}
            target="_blank"
            rel="noreferrer"
            href={'mailto:info@qryptolabs.io'}
          >
            info@qryptolabs.io
          </Link>
        </div>
      </section>
    </div>
  )
}

export default App
